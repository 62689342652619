const projects = [
  {
    name: "Netflix Clone",
    description:
      "Its a very basic netflix clone frontend only created with reactjs uses axios as well as async code to make API calls to a movie API TMDB",
    link1: "https://foc1r.csb.app/",
    link2: "https://github.com/ivinayakg/netflix-clone",
    createdOn: "September, 2021",
  },
  {
    name: "Netflix Clone",
    description:
      "Its a very basic netflix clone frontend only created with reactjs uses axios as well as async code to make API calls to a movie API TMDB",
    link1: "https://foc1r.csb.app/",
    link2: "https://github.com/ivinayakg/netflix-clone",
    createdOn: "September, 2021",
  },
  {
    name: "Netflix Clone",
    description:
      "Its a very basic netflix clone frontend only created with reactjs uses axios as well as async code to make API calls to a movie API TMDB",
    link1: "https://foc1r.csb.app/",
    link2: "https://github.com/ivinayakg/netflix-clone",
    createdOn: "September, 2021",
  },
  {
    name: "Netflix Clone",
    description:
      "Its a very basic netflix clone frontend only created with reactjs uses axios as well as async code to make API calls to a movie API TMDB",
    link1: "https://foc1r.csb.app/",
    link2: "https://github.com/ivinayakg/netflix-clone",
    createdOn: "September, 2021",
  },
  {
    name: "Netflix Clone",
    description:
      "Its a very basic netflix clone frontend only created with reactjs uses axios as well as async code to make API calls to a movie API TMDB",
    link1: "https://foc1r.csb.app/",
    link2: "https://github.com/ivinayakg/netflix-clone",
    createdOn: "September, 2021",
  },
  {
    name: "Netflix Clone",
    description:
      "Its a very basic netflix clone frontend only created with reactjs uses axios as well as async code to make API calls to a movie API TMDB",
    link1: "https://foc1r.csb.app/",
    link2: "https://github.com/ivinayakg/netflix-clone",
    createdOn: "September, 2021",
  },
  {
    name: "Netflix Clone",
    description:
      "Its a very basic netflix clone frontend only created with reactjs uses axios as well as async code to make API calls to a movie API TMDB",
    link1: "https://foc1r.csb.app/",
    link2: "https://github.com/ivinayakg/netflix-clone",
    createdOn: "September, 2021",
  },
  {
    name: "Netflix Clone",
    description:
      "Its a very basic netflix clone frontend only created with reactjs uses axios as well as async code to make API calls to a movie API TMDB",
    link1: "https://foc1r.csb.app/",
    link2: "https://github.com/ivinayakg/netflix-clone",
    createdOn: "September, 2021",
  },
  {
    name: "Netflix Clone",
    description:
      "Its a very basic netflix clone frontend only created with reactjs uses axios as well as async code to make API calls to a movie API TMDB",
    link1: "https://foc1r.csb.app/",
    link2: "https://github.com/ivinayakg/netflix-clone",
    createdOn: "September, 2021",
  },
  {
    name: "Netflix Clone",
    description:
      "Its a very basic netflix clone frontend only created with reactjs uses axios as well as async code to make API calls to a movie API TMDB",
    link1: "https://foc1r.csb.app/",
    link2: "https://github.com/ivinayakg/netflix-clone",
    createdOn: "September, 2021",
  },
  {
    name: "Netflix Clone",
    description:
      "Its a very basic netflix clone frontend only created with reactjs uses axios as well as async code to make API calls to a movie API TMDB",
    link1: "https://foc1r.csb.app/",
    link2: "https://github.com/ivinayakg/netflix-clone",
    createdOn: "September, 2021",
  },
];

export default projects;
